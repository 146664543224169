import { Fragment, useState } from 'react';
import { classNames } from '../../utils';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { CldImage } from 'next-cloudinary';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export function LocalisationSelect(props) {
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();
  const { pathname, asPath, query, locale } = router;
  const { t } = useTranslation('common');

  const changeLanguage = (event) => {
    const locale = event;
    router.push({ pathname, query }, asPath, { locale: locale });
  };

  return (
    <div className="relative">
      <Listbox defaultValue={locale} onChange={(event) => changeLanguage(event)}>
        {({ open }) => (
          <>
            <Listbox.Button
              className="relative w-full cursor-pointer rounded-lg py-3 text-left focus:outline-none sm:text-sm"
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}>
              <span className="flex items-center gap-1">
                <span className="relative h-5 w-5">
                  <CldImage
                    src={
                      locale == 'de'
                        ? 'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717609704/static/flags/zuizrhp4iqvdmottngeu.png'
                        : 'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717613165/static/flags/kbs5kf8u8g3cpabp2udg.png'
                    }
                    alt=""
                    objectFit="cover"
                    width={256}
                    height={256}
                    className="rounded-full"
                  />
                </span>
                <span className="block truncate font-bold"> {locale.toUpperCase()}</span>
              </span>
            </Listbox.Button>
            {isOpen && (
              <Transition as={Fragment} show={isOpen}>
                <Listbox.Options
                  className="absolute right-0 top-[90%] z-10 mt-1 max-h-60 w-screen max-w-[260px] overflow-auto rounded-3xl bg-white px-4 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:px-0 sm:text-sm"
                  onMouseEnter={() => setIsOpen(true)}
                  onMouseLeave={() => setIsOpen(false)}>
                  <div className="flex items-center gap-3 px-6 py-3 text-xs font-bold uppercase text-gray-400">
                    {t('language')} <div className="w-full border-b border-neutral-200" />
                  </div>
                  <Listbox.Option
                    value={'de'}
                    className={({ active }) =>
                      classNames(
                        active ? '' : '',
                        'relative cursor-pointer select-none px-6 py-2 pr-10 hover:bg-gray-100'
                      )
                    }>
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-medium' : 'font-normal',
                            'flex items-center gap-2 text-sm'
                          )}>
                          <span className="relative mr-2 h-5 w-5">
                            <CldImage
                              src="https://res.cloudinary.com/drt9lfnfg/image/upload/v1717609704/static/flags/zuizrhp4iqvdmottngeu.png"
                              alt=""
                              objectFit="cover"
                              width={256}
                              height={256}
                              className="rounded-full"
                            />
                          </span>
                          Deutsch
                        </span>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-black' : 'text-black',
                              'absolute inset-y-0 right-0 flex items-center pr-10'
                            )}>
                            <CheckIcon className="h-4 w-4 stroke-2" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                  <Listbox.Option
                    value={'en'}
                    className={({ active }) =>
                      classNames(
                        active ? '' : '',
                        'relative cursor-pointer select-none px-6 py-2 pr-10 hover:bg-gray-100'
                      )
                    }>
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-medium' : 'font-normal',
                            'flex items-center gap-2 text-sm'
                          )}>
                          <span className="relative mr-2 h-5 w-5">
                            <CldImage
                              src="https://res.cloudinary.com/drt9lfnfg/image/upload/v1717613165/static/flags/kbs5kf8u8g3cpabp2udg.png"
                              alt=""
                              objectFit="cover"
                              width={256}
                              height={256}
                              className="rounded-full"
                            />
                          </span>
                          English
                        </span>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-black' : 'text-black',
                              'absolute inset-y-0 right-0 flex items-center pr-10'
                            )}>
                            <CheckIcon className="h-4 w-4 stroke-2" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                </Listbox.Options>
              </Transition>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
}
