import React, { useEffect, useState } from 'react';
import TonstudioForm from './tonstudio-search-form/TonstudioForm';
import ServicesSearchForm from './services-search-form/ServicesSearchForm';
import { useTranslation } from 'next-i18next';

const TABS = ['Dienstleistungen', 'Tonstudios'];

const HeroSearchFormSmall = ({ className = '', defaultTab = 'Dienstleistungen', onTabChange, defaultFieldFocus }) => {
  const [tabActive, setTabActive] = useState('');

  const { t } = useTranslation('common');

  useEffect(() => {
    setTabActive(defaultTab);
  }, [defaultTab]);

  const renderTab = () => {
    return (
      <ul className="flex h-[88px] justify-center space-x-5 sm:space-x-9">
        {TABS.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => {
                setTabActive(tab);
                onTabChange && onTabChange(tab);
              }}
              className={`relative flex flex-shrink-0 cursor-pointer items-center text-base ${
                active ? 'font-medium text-neutral-900  ' : 'text-neutral-500   '
              } `}
              key={tab}>
              <div className="relative select-none">
                <span>{tab == 'Tonstudios' ? t('recording_studios') : t('services')}</span>
                {active && (
                  <span className="absolute top-full mr-2 mt-1 block h-0.5 w-full rounded-full bg-neutral-800" />
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  };
  // Tonstudio, Profile, Online Leistungen, Lokale Leistungen
  const renderForm = () => {
    switch (tabActive) {
      case 'Tonstudios':
        return <TonstudioForm defaultFieldFocus={defaultFieldFocus} />;
      case 'Dienstleistungen':
        return <ServicesSearchForm defaultFieldFocus={defaultFieldFocus} />;

      default:
        return <ServicesSearchForm defaultFieldFocus={defaultFieldFocus} />;
    }
  };

  return (
    <div className={`nc-HeroSearchFormSmall ${className}`} data-nc-id="HeroSearchFormSmall">
      {renderTab()}
      <div className="mt-2">{renderForm()}</div>
    </div>
  );
};

export default HeroSearchFormSmall;
