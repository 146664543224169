import React from 'react';
import { CldImage } from 'next-cloudinary';
//

const imageUrls = [
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722663/static/avatars/l53praw4cyqahbklzbgr.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722663/static/avatars/vrichlf1hv97qvsyygfh.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722662/static/avatars/uobijkkaixop4et69lia.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722662/static/avatars/eqzajtrejt5uo42uxrf1.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722662/static/avatars/uiunmgksdmtfbsi0rrgh.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722662/static/avatars/qriwkcfvo4l3gym8xcjf.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722662/static/avatars/jupzo2iocrjjrvdphnkc.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722661/static/avatars/zoktatbz9yqu4sv5yytp.png',
];

// Select a random URL from the array
const randomIndex = Math.floor(Math.random() * imageUrls.length);
const selectedImageUrl = imageUrls[randomIndex];

const Avatar = ({
  containerClassName = 'ring-1 ring-white',
  sizeClass = 'h-6 w-6 text-sm',
  radius = 'rounded-full',
  imgUrl,
  userName,
  hasChecked,
  hasCheckedClass = 'w-4 h-4 -top-0.5 -right-0.5',
}) => {
  const url = imgUrl || '';

  return (
    <div
      className={`relative inline-flex flex-shrink-0 items-center justify-center font-semibold uppercase text-neutral-100 shadow-inner ${radius} ${sizeClass} ${containerClassName}`}>
      <CldImage
        src={url === 'avatar-man' || !url ? selectedImageUrl : url}
        width={150}
        height={150}
        objectFit="cover"
        className="rounded-full bg-gray-200"
        alt="avatar"
      />
      {hasChecked && (
        <span
          className={` absolute flex items-center justify-center rounded-full bg-teal-500 text-xs text-white  ${hasCheckedClass}`}>
          <i className="las la-check"></i>
        </span>
      )}
    </div>
  );
};

export default Avatar;
