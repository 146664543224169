import { forwardRef } from 'react';

export const TooltipWrapper = forwardRef((props, ref) => {
  let { children, ...rest } = props;
  return (
    <div {...props} ref={ref}>
      {children}
    </div>
  );
});
TooltipWrapper.displayName = 'TooltipWrapper';
