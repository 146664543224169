import { converSelectedDateToString, convertNumbThousand } from '../../../../utils';
import React, { useCallback, useEffect, useState } from 'react';
import DatesRangeInput from '../DatesRangeInput';
import LocationInput from '../LocationInput';
import GuestsInput from '../GuestsInput';
import SearchInput from '../SearchInput';
import PriceRangeInput from '../PriceRangeInput';
import { useTranslation } from 'next-i18next';

const ServicesSearchForm = ({ setHrefLink }) => {
  const [dropOffLocationType, setDropOffLocationType] = useState('Lokale Leistungen');
  const [fieldNameShow, setFieldNameShow] = useState('locationInput');
  const [locationInput, setLocationInput] = useState('');
  const [checkinInput, setCheckinInput] = useState('');
  const [checkoutInput, setCheckoutInput] = useState('');
  const [guestInput, setGuestInput] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [priceInput, setPriceInput] = useState([5, 9999]);
  //
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(0);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);

  const { t } = useTranslation('common');

  useEffect(() => {
    if (dropOffLocationType === 'Online Leistungen') {
      setHrefLink({
        pathname: '/online-leistungen',
        query: {
          filters: JSON.stringify({
            search: searchInput,
            priceMin: priceInput[0],
            priceMax: priceInput[1],
            sortBy: 'Empfohlen',
          }),
          page: 1,
        },
      });
    } else {
      setHrefLink({
        pathname: '/lokale-leistungen',
        query: {
          filters: JSON.stringify({
            checkIn: checkinInput,
            checkOut: checkoutInput ? checkoutInput : checkinInput,
            location: locationInput,
          }),
          moreFilters: JSON.stringify({
            guest: guestInput,
          }),
          page: 1,
        },
      });
    }
  }, [dropOffLocationType, locationInput, checkinInput, checkoutInput, guestInput, searchInput, priceInput]);

  //

  const handleLocalChange = useCallback((e, second, third = false) => {
    const val = e?.target?.value;
    let name =
      e === 'dates'
        ? 'dates'
        : e === 'GuestsInput'
        ? 'GuestsInput'
        : e === 'searchInput'
        ? 'searchInput'
        : e === 'priceInput'
        ? 'priceInput'
        : e?.target?.name;
    if (name === 'locationInput') {
      if (third == 'click') {
        setLocationInput(val ? val : second ? second : '');
        setFieldNameShow('guests');
        return;
      }
      setLocationInput(val ? val : second ? second : '');
      return;
    }
    if (name === 'dates') {
      const [start, end] = second;
      setCheckinInput(start);
      setCheckoutInput(end);
      return;
    }
    if (name === 'GuestsInput') {
      setGuestInput(second);
      return;
    }
    if (name === 'searchInput') {
      if (third == 'click') {
        setSearchInput(second);
        setFieldNameShow('price');
        return;
      }
      setSearchInput(second);
      return;
    }
    if (name === 'priceInput') {
      setPriceInput(second);
      return;
    }
  }, []);
  //

  const renderInputLocation = () => {
    const isActive = fieldNameShow === 'locationInput';
    return (
      <div
        className={`w-full bg-white ${
          isActive ? 'rounded-2xl shadow-lg' : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}>
        {!isActive ? (
          <button
            className={`flex w-full justify-between p-4 text-sm font-medium`}
            onClick={() => setFieldNameShow('locationInput')}>
            <span className="text-neutral-400">{t('where')}</span>
            <span>{locationInput || t('i_am_flexibel')}</span>
          </button>
        ) : (
          <LocationInput headingText={`${t('location_input_question')}`} handleLocalChange={handleLocalChange} />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === 'dates';
    return (
      <div
        className={`w-full overflow-hidden bg-white   ${
          isActive ? 'rounded-2xl shadow-lg' : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}>
        {!isActive ? (
          <button
            className={`flex w-full justify-between p-4 text-sm font-medium  `}
            onClick={() => setFieldNameShow('dates')}>
            <span className="text-neutral-400">{t('when')}</span>
            <span>{checkinInput ? converSelectedDateToString([checkinInput, checkoutInput]) : t('add_dates')}</span>
          </button>
        ) : (
          <DatesRangeInput handleLocalChange={handleLocalChange} />
        )}
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === 'guests';
    const totalGuests = guestChildrenInputValue + guestAdultsInputValue;
    return (
      <div
        className={`w-full overflow-hidden bg-white   ${
          isActive ? 'rounded-2xl shadow-lg' : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}>
        {!isActive ? (
          <button
            className={`flex w-full justify-between p-4 text-sm font-medium`}
            onClick={() => setFieldNameShow('guests')}>
            <span className="text-neutral-400">{t('who')}</span>
            <span>{totalGuests ? totalGuests + t('guests') : t('add_guests')}</span>
          </button>
        ) : (
          <GuestsInput
            guestChildrenInputValue={guestChildrenInputValue}
            setGuestChildrenInputValue={setGuestChildrenInputValue}
            guestAdultsInputValue={guestAdultsInputValue}
            setGuestAdultsInputValue={setGuestAdultsInputValue}
            handleLocalChange={handleLocalChange}
          />
        )}
      </div>
    );
  };
  //
  const renderInputSearch = () => {
    const isActive = fieldNameShow === 'search';
    return (
      <div
        className={`w-full bg-white ${
          isActive ? 'rounded-2xl shadow-lg' : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}>
        {!isActive ? (
          <button
            className={`flex w-full justify-between p-4 text-sm font-medium`}
            onClick={() => setFieldNameShow('search')}>
            <span className="text-neutral-400">Keywords</span>
            <span>{searchInput || t('all_services')}</span>
          </button>
        ) : (
          <SearchInput headingText="Keywords?" handleLocalChange={handleLocalChange} />
        )}
      </div>
    );
  };

  const renderInputPrice = () => {
    const isActive = fieldNameShow === 'price';

    return (
      <div
        className={`w-full overflow-hidden bg-white   ${
          isActive ? 'rounded-2xl shadow-lg' : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}>
        {!isActive ? (
          <button
            className={`flex w-full justify-between p-4 text-sm font-medium`}
            onClick={() => setFieldNameShow('price')}>
            <span className="text-neutral-400">{t('price_range') + ' ?'}</span>
            <span>{`${convertNumbThousand(priceInput[0])}€ ~ ${convertNumbThousand(priceInput[1])}€`}</span>
          </button>
        ) : (
          <PriceRangeInput handleLocalChange={handleLocalChange} />
        )}
      </div>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className="flex items-center justify-center space-x-3">
        <div
          className={`flex cursor-pointer items-center rounded-full px-4 py-1.5 text-xs font-medium ${
            dropOffLocationType === 'Lokale Leistungen'
              ? 'bg-primary text-white shadow-lg shadow-black/10'
              : 'border border-neutral-300  '
          }`}
          onClick={(e) => {
            setDropOffLocationType('Lokale Leistungen');
            setFieldNameShow('locationInput');
          }}>
          {t('local_services')}
        </div>
        <div
          className={`flex cursor-pointer items-center rounded-full px-4 py-1.5 text-xs font-medium ${
            dropOffLocationType === 'Online Leistungen'
              ? 'bg-primary text-white shadow-lg shadow-black/10'
              : 'border border-neutral-300  '
          }`}
          onClick={(e) => {
            setDropOffLocationType('Online Leistungen');
            setFieldNameShow('search');
          }}>
          {t('online_services')}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {renderRadioBtn()}
        {/*  */}
        {dropOffLocationType === 'Lokale Leistungen' ? renderInputLocation() : null}
        {/*  */}
        {dropOffLocationType === 'Lokale Leistungen' ? renderInputGuests() : null}
        {/*  */}
        {dropOffLocationType === 'Lokale Leistungen' ? renderInputDates() : null}

        {/*  */}
        {dropOffLocationType === 'Online Leistungen' ? renderInputSearch() : null}
        {/*  */}
        {dropOffLocationType === 'Online Leistungen' ? renderInputPrice() : null}
      </div>
    </div>
  );
};

export default ServicesSearchForm;
