import React, { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import Slider from 'rc-slider';
import { convertNumbThousand } from '../../../../utils';
import ButtonSubmit from '../ButtonSubmit';
import { CurrencyEuroIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

const PriceRangeInput = ({
  theSearchLink,
  handleLocalChange,
  fieldClassName = '[ nc-hero-field-padding--small ]',
  setShowPopover,
  showPopover,
}) => {
  const [pricingRange, setPricingRange] = useState([5, 9999]);
  const [minPrice, setMinPrice] = useState(pricingRange[0]);
  const [maxPrice, setMaxPrice] = useState(pricingRange[1]);

  const { t } = useTranslation('common');

  useEffect(() => {
    handleLocalChange('priceInput', pricingRange);
  }, [pricingRange]);

  const handleMinPriceChange = (e) => {
    const value = Math.max(Number(Math.min(Number(e.target.value), 9999)), 5); // Ensure value does not go below the minimum
    if (value > maxPrice) return;
    setMinPrice(value);
    setPricingRange([value, pricingRange[1]]);
  };

  const handleMaxPriceChange = (e) => {
    const value = Math.min(Number(Math.max(Number(e.target.value), 5)), 9999); // Ensure value does not go above the maximum
    if (value < minPrice) {
      setMaxPrice(minPrice);
      return;
    }
    setMaxPrice(value);
    setPricingRange([pricingRange[0], value]);
  };

  const handleSliderChange = (newRange) => {
    setPricingRange(newRange);
    const value = Math.min(Number(newRange[1]), 9999); // Ensure value does not go above the maximum
    setMaxPrice(value);
    const value2 = Math.max(Number(newRange[0]), 5); // Ensure value does not go below the minimum
    setMinPrice(value2);
  };

  return (
    <Popover className="relative flex flex-[1.3]">
      {({ open, close }) => (
        <>
          <div
            className={`z-10 flex flex-1 cursor-pointer items-center focus:outline-none ${
              open ? 'nc-hero-field-focused' : ''
            }`}>
            {/*  */}
            <Popover.Button
              id="preis"
              className={`flex flex-1 items-center text-left focus:outline-none ${fieldClassName} space-x-3 `}
              onClickCapture={() => document.querySelector('html')?.click()}
              onClick={() => setShowPopover(5)}>
              <div className="text-neutral-300">
                <CurrencyEuroIcon className="h-5 w-5 lg:h-7 lg:w-7" />
              </div>
              <div className="flex-grow">
                <span className="block truncate font-semibold xl:text-lg">
                  {`${convertNumbThousand(pricingRange[0])}€ ~ ${convertNumbThousand(pricingRange[1])}€`}
                </span>
                <span className="mt-1 block text-sm font-light leading-none text-neutral-400 ">
                  {t('price_input_desc')}
                </span>
              </div>
            </Popover.Button>

            {/* BUTTON SUBMIT OF FORM */}
            <div className="pr-2">
              <ButtonSubmit href={theSearchLink} />
            </div>
          </div>

          {open && (
            <div className="absolute -left-0.5 right-1 top-1/2 z-0 h-8 -translate-y-1/2 self-center bg-white  "></div>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute left-0 top-full z-10 mt-3 w-full max-w-sm rounded-3xl bg-white px-4 py-5 shadow-xl   sm:min-w-[340px] sm:px-8 sm:py-6 lg:right-0">
              <div className="relative flex flex-col space-y-8">
                <div className="space-y-5">
                  <span className="font-medium">{t('price_range')} </span>
                  <Slider
                    range
                    trackStyle={{ backgroundColor: '#6366F1' }}
                    handleStyle={{
                      borderColor: '#6366F1',
                      backgroundColor: '#6366F1',
                      opacity: '100%',
                    }}
                    min={5}
                    max={9999}
                    defaultValue={[pricingRange[0], pricingRange[1]]}
                    value={[pricingRange[0], pricingRange[1]]}
                    allowCross={false}
                    step={5}
                    onChange={handleSliderChange}
                  />
                </div>

                <div className="flex justify-between space-x-3">
                  <div>
                    <div className="flex w-full items-center gap-0 rounded-md border-[0.5px] border-gray-400 px-2">
                      <label className="whitespace-nowrap text-sm text-gray-400">min</label>
                      <input
                        max={9999}
                        min={5}
                        step={5}
                        type="number"
                        placeholder="min"
                        value={minPrice}
                        onChange={handleMinPriceChange}
                        className="w-full border-none"
                      />
                      <label className="font-normal text-gray-400">€</label>
                    </div>
                  </div>
                  <div>
                    <div className="flex w-full items-center gap-0 rounded-md border-[0.5px] border-gray-300 px-2">
                      <label className="whitespace-nowrap text-sm text-gray-400">max</label>
                      <input
                        max={9999}
                        min={5}
                        step={5}
                        type="number"
                        placeholder="max"
                        value={maxPrice}
                        onChange={handleMaxPriceChange}
                        className="w-full border-none"
                      />
                      <label className="font-normal text-gray-400">€</label>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PriceRangeInput;
