import React, { useEffect, useState } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';

const NcInputNumber = ({ className = 'w-full', defaultValue = 0, min = 0, max, onChange, label, desc }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClickDecrement = () => {
    if (min >= value) return;
    setValue((state) => {
      return state - 1;
    });
    onChange && onChange(value - 1);
  };
  const handleClickIncrement = () => {
    if (max && max <= value) return;
    setValue((state) => {
      return state + 1;
    });
    onChange && onChange(value + 1);
  };

  const renderLabel = () => {
    return (
      <div className="flex flex-col">
        <span className="font-medium text-neutral-800  ">{label}</span>
        {desc && <span className="text-xs font-normal text-neutral-500  ">{desc}</span>}
      </div>
    );
  };

  return (
    <div
      className={`nc-NcInputNumber flex items-center justify-between space-x-5 ${className}`}
      data-nc-id="NcInputNumber">
      {label && renderLabel()}

      <div className={`nc-NcInputNumber flex w-28 items-center justify-between`}>
        <button
          id="decrement"
          className="flex h-8 w-8 items-center justify-center rounded-full border border-neutral-400 bg-white hover:border-neutral-700 focus:outline-none disabled:cursor-default disabled:opacity-50 disabled:hover:border-neutral-400  "
          type="button"
          onClick={handleClickDecrement}
          disabled={min >= value}>
          <MinusIcon className="h-4 w-4" />
        </button>
        <span>{value}</span>
        <button
          id="increment"
          className="flex h-8 w-8 items-center justify-center rounded-full border border-neutral-400 bg-white hover:border-neutral-700 focus:outline-none disabled:cursor-default disabled:opacity-50 disabled:hover:border-neutral-400  "
          type="button"
          onClick={handleClickIncrement}
          disabled={max ? max <= value : false}>
          <PlusIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default NcInputNumber;
