import React from 'react';
import { MyLink } from '../../MyLink';

const ButtonSubmit = ({ className = '', href = '/listing-stay-map' }) => {
  return (
    <MyLink
      prefetch={false}
      href={href}
      type="button"
      className={`relative flex h-14 w-14 items-center justify-center rounded-full bg-indigo-500 text-neutral-50 hover:bg-primary-700 focus:outline-none ${className}`}
      onClick={() => {
        document.querySelector('.nc-Footer')?.click();
      }}>
      <span className="max-md:mr-3 md:sr-only">Suchen</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-1/2 top-1/2 h-6 w-6 -translate-x-1/2 -translate-y-1/2 transform"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </MyLink>
  );
};

export default ButtonSubmit;
