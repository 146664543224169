import React, { useState } from 'react';
import { useEffect } from 'react';
import ClearDataButton from './ClearDataButton';
import { useRef } from 'react';
import { useOutsideAlerter } from '../../../utils';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const SearchInput = ({
  handleLocalChange,
  autoFocus = false,
  onInputDone,
  placeHolder = 'Keywords',
  desc = 'Nach welcher Online Leistung suchst du?',
  className = 'nc-flex-1.5',
  divHideVerticalLineClass = 'left-10 -right-0.5',
  setShowPopover,
  showPopover,
}) => {
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const [value, setValue] = useState('');

  useEffect(() => {
    setShowPopover(autoFocus ? 4 : 0);
    if (autoFocus && !!inputRef.current) {
      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      }, 200);
    }
  }, [autoFocus]);

  useEffect(() => {
    handleLocalChange('searchInput', value);
  }, [value]);

  useEffect(() => {
    if (showPopover == 4 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  useOutsideAlerter(containerRef, () => {
    setShowPopover(false);
  });

  // const handleSelectLocation = (item) => {
  //   setValue(item);
  //   onInputDone && onInputDone(item);
  //   setShowPopover(false);
  // };

  // const renderRecentSearches = () => {
  //   return (
  //     <>
  //       <h3 className="mt-2 block px-4 text-base font-semibold text-neutral-800   sm:mt-0 sm:px-8">Recent searches</h3>
  //       <div className="mt-2">
  //         {[
  //           'Hamptons, Suffolk County, NY',
  //           'Las Vegas, NV, United States',
  //           'Ueno, Taito, Tokyo',
  //           'Ikebukuro, Toshima, Tokyo',
  //         ].map((item) => (
  //           <span
  //             onClick={() => handleSelectLocation(item)}
  //             key={item}
  //             className="flex cursor-pointer items-center space-x-3 px-4 py-4 hover:bg-neutral-100   sm:px-6">
  //             <span className="block text-neutral-400">
  //               <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
  //             </span>
  //             <span className=" block text-neutral-700  ">{item}</span>
  //           </span>
  //         ))}
  //       </div>
  //     </>
  //   );
  // };

  const renderSearchValues = ({ heading, items }) => {
    return (
      <>
        <p className="block px-4 text-sm font-medium">{heading || 'Keywords'}</p>
        <div className="mt-3">
          {items?.map((item) => (
            <span
              onClick={() => {
                setValue(item);
                setShowPopover(false);
              }}
              key={item}
              className="flex cursor-pointer items-center space-x-3 px-4 py-4 hover:bg-neutral-100 sm:px-6">
              <span className="block text-neutral-400">
                <MagnifyingGlassIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block text-neutral-700">{item}</span>
            </span>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(4)}
        className={`[ nc-hero-field-padding--small ] relative z-10 flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 text-left focus:outline-none ${
          showPopover == 4 ? 'nc-hero-field-focused--2' : ''
        }`}>
        <div className="flex-1">
          <input
            className={`block w-full truncate border-none bg-transparent p-0 font-semibold placeholder-neutral-800 focus:placeholder-neutral-400 focus:outline-none focus:ring-0   xl:text-base`}
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover == 4}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="mt-0.5 block text-sm font-light text-neutral-400 ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
          {value && showPopover == 4 && <ClearDataButton onClick={() => setValue('')} />}
        </div>
      </div>
      {showPopover == 4 &&
        (value ? null : (
          <div
            className={`specific-class absolute left-0 top-full z-40 mt-3 max-h-96 w-full min-w-[300px] overflow-y-auto rounded-3xl bg-white py-3 shadow-xl sm:min-w-[400px] sm:py-5`}>
            {renderSearchValues({
              heading: 'Beliebte Keywords',
              items: ['Mischen', 'Mastern', 'Songwriting', 'Youtube editing', 'Podcast editing'],
            })}
          </div>
        ))}
    </div>
  );
};

export default SearchInput;
