import { MapPinIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect, useRef, FC } from 'react';
import { useTranslation } from 'next-i18next';

const LocationInput = ({
  handleLocalChange,
  className = '',
  defaultValue = 'Ich bin flexibel',
  headingText = 'Standort?',
}) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const { t } = useTranslation('common');

  const handleGetSuggestions = async (value) => {
    handleLocalChange(value);
    setValue(value.target.value);
    const fetchLocationQueryCoordinates = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${value.target.value}.json?language=de&country=de,at,ch&types=country,place,district,region,address,postcode&access_token=${process.env.mapbox_key}`
    );
    // const fetchLocationQueryCoordinates = await fetch(
    //   `https://api.mapbox.com/search/searchbox/v1/suggest?q=${value.target.value}?limit=4&language=de&session_token=${
    //     user.id || user.temporaryID
    //   }&country=de,at,ch&access_token=${process.env.mapbox_key}`
    // );
    const getLocationQueryCoordinates = await fetchLocationQueryCoordinates.json();
    let suggestions;
    suggestions = getLocationQueryCoordinates.features?.map((sug) => {
      return { full_address: sug.place_name };
    });
    // const response = await autofill.suggest(value.target.value || '0', {
    //   sessionToken: user.id,
    //   limit: 4,
    //   language: 'de',
    //   country: 'DE,AT,CH',
    //   types: 'region',
    // });
    if (suggestions.length > 0) {
      setSuggestions(suggestions);
    } else {
      setSuggestions([]);
    }
  };
  //
  const handleSelectLocation = (item) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValue(item);
      handleLocalChange({ target: { name: 'locationInput' } }, item, 'click');
      setSuggestions([]);
    }, 0);
  };
  //
  useEffect(() => {
    if (value?.length < 0) {
      setShowPopover(false);
      setSuggestions([]);
    }
  }, [value]);
  //
  const renderSearchValues = ({ heading }) => {
    if (!suggestions.length > 0) {
      setSuggestions([{ full_address: 'Berlin' }, { full_address: 'Hamburg' }, { full_address: 'Frankfurt' }]);
    }
    return (
      <>
        <p className="block text-base font-semibold">{heading || 'Destinations'}</p>
        <div className="mt-3">
          {suggestions.map((item) => {
            return (
              <div
                className="mb-1 flex items-center space-x-3 py-2 text-sm"
                onClick={() => handleSelectLocation(item.full_address)}
                key={item.full_address}>
                <MapPinIcon className="h-5 w-5 shrink-0 text-neutral-500  " />
                <span className="">{item.full_address}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block text-xl font-semibold sm:text-2xl">{headingText}</span>
        <div className="relative mt-5">
          <input
            className={`block w-full truncate rounded-xl border border-neutral-900 bg-transparent px-4 py-3 pr-12 text-base font-bold leading-none placeholder-neutral-500 placeholder:truncate focus:outline-none focus:ring-0  `}
            placeholder={t('search_a_location')}
            value={value}
            name="locationInput"
            onChange={(e) => {
              handleGetSuggestions(e);
            }}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="h-5 w-5 text-neutral-700" />
          </span>
        </div>
        <div className="mt-7">
          {value
            ? renderSearchValues({
                heading: t('suggestions'),
              })
            : renderSearchValues({
                heading: t('popular_locations'),
              })}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
