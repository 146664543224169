import React from 'react';

const Radiobutton = ({ subLabel = '', label = '', name, id, className = '', defaultChecked, onChange }) => {
  return (
    <label htmlFor={label} className={`flex text-sm sm:text-base ${className}`}>
      <input
        id={id}
        name={name}
        type="radio"
        className="focus:ring-action-primary border-primary h-6   w-6 rounded border-neutral-500 bg-white text-primary-500  focus:ring-primary-500  "
        defaultChecked={defaultChecked}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      {label && (
        <div className="ml-3.5 flex flex-1 flex-col justify-center">
          <span className=" text-neutral-900  ">{label}</span>
          {subLabel && <p className="mt-1 text-sm font-light text-neutral-500  ">{subLabel}</p>}
        </div>
      )}
    </label>
  );
};

export default Radiobutton;
