import React, { useEffect, useRef, useState } from 'react';
import DatePickerCustomHeaderTwoMonth from '../../DatePickerCustomHeaderTwoMonth';
import DatePickerCustomDay from '../../DatePickerCustomDay';
import DatePicker from 'react-datepicker';
import ClearDataButton from '../ClearDataButton';
import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const LokalDatesRangeInput = ({
  handleLocalChange,
  className = '[ lg:nc-flex-2 ]',
  fieldClassName = '[ nc-hero-field-padding--small ]',
  autoFocus = false,
  setShowPopover,
  showPopover,
}) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const containerRef = useRef(null);
  const { t } = useTranslation('common');
  const router = useRouter();
  const { locale } = router;

  useEffect(() => {
    const id = containerRef.current.getAttribute('id');
    const childDiv = containerRef.current.querySelector('.specific-class');
    function handleClickOutside(event) {
      event.stopPropagation();
      if (id == 2 && childDiv && containerRef.current && !containerRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopover, containerRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    handleLocalChange('dates', dates);
  };

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        setShowPopover(2);
      }, 200);
    }
  }, [autoFocus]);

  const renderStartInput = () => {
    return (
      <>
        <div className="flex-grow text-left">
          <span className="line-clamp-1 font-semibold xl:text-base">
            {startDate?.toLocaleDateString('de-DE', {
              month: 'short',
              day: '2-digit',
            }) || t('starttime')}
          </span>
          <span className="mt-1 line-clamp-1 text-sm font-light leading-none text-neutral-400">{t('select_date')}</span>
        </div>
      </>
    );
  };
  const renderEndInput = () => {
    return (
      <>
        <div className="flex-grow text-left">
          <span className="line-clamp-1 font-semibold xl:text-base">
            {endDate
              ? endDate?.toLocaleDateString('de-DE', {
                  month: 'short',
                  day: '2-digit',
                })
              : t('endtime')}
          </span>
          <span className="mt-1 line-clamp-1 text-sm font-light leading-none text-neutral-400">{t('select_date')}</span>
        </div>
      </>
    );
  };

  return (
    <div className={`StayDatesRangeInput relative z-60 flex ${className}`} id={2} ref={containerRef}>
      <button
        type="button"
        id="date 1"
        className={`relative z-10 flex flex-1 ${fieldClassName} items-center space-x-3 focus:outline-none ${
          showPopover == 2 && !startDate ? 'nc-hero-field-focused--2' : ''
        }`}
        onClick={() => setShowPopover(2)}>
        {renderStartInput()}
        <div className="z-60">
          {(startDate && open) || (showPopover == 2 && startDate) ? (
            <ClearDataButton onClick={() => onChangeDate([null, null])} />
          ) : null}
        </div>
      </button>

      <div className="h-8 self-center border-r border-slate-200  "></div>
      <button
        type="button"
        id="date 2"
        className={`relative z-10 flex flex-1 ${fieldClassName} items-center space-x-3 focus:outline-none ${
          startDate && showPopover == 2 ? 'nc-hero-field-focused--2' : ''
        }`}
        onClick={() => setShowPopover(2)}>
        {renderEndInput()}
        {endDate && showPopover == 2 ? <ClearDataButton onClick={() => onChangeDate([null, null])} /> : null}
      </button>

      {showPopover == 2 && (
        <div className="absolute -inset-x-0.5 top-1/2 z-0 h-8 -translate-y-1/2 self-center bg-white  "></div>
      )}

      {showPopover == 2 ? (
        <div className="specific-class absolute left-1/2 top-full z-10 mt-3 w-screen max-w-3xl -translate-x-1/2 transform px-4 sm:px-0">
          <div className="overflow-hidden rounded-3xl bg-white p-8 shadow-lg ring-1 ring-black ring-opacity-5">
            <DatePicker
              selected={startDate}
              onChange={onChangeDate}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              monthsShown={2}
              showPopperArrow={false}
              inline
              minDate={new Date()}
              locale={locale == 'en' ? enUS : de}
              renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth locale={locale} {...p} />}
              renderDayContents={(day, date) => <DatePickerCustomDay dayOfMonth={day} date={date} />}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LokalDatesRangeInput;
