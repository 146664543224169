import DatePicker from 'react-datepicker';
import React, { useState } from 'react';
import DatePickerCustomHeaderTwoMonth from '../DatePickerCustomHeaderTwoMonth';
import DatePickerCustomDay from '../DatePickerCustomDay';
import de from 'date-fns/locale/de';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import enUS from 'date-fns/locale/en-US';

const StayDatesRangeInput = ({ handleLocalChange, className = '', change }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { t } = useTranslation('common');
  const router = useRouter();
  const { locale } = router;

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    handleLocalChange('dates', dates);
  };

  return (
    <div>
      <div className="p-5">
        <span className="block text-xl font-semibold sm:text-2xl">{t('when_is_your_session')}</span>
      </div>
      <div className={`relative z-10 flex flex-shrink-0 justify-center py-5 ${className} `}>
        <DatePicker
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={2}
          showPopperArrow={false}
          inline
          minDate={new Date()}
          locale={locale == 'en' ? enUS : de}
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth locale={locale} {...p} />}
          renderDayContents={(day, date) => <DatePickerCustomDay dayOfMonth={day} date={date} />}
        />
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
