import React, { useState } from 'react';
import { useEffect } from 'react';
import ClearDataButton from './ClearDataButton';
import { useRef } from 'react';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';

const LocationInput = ({
  handleLocalChange,
  autoFocus = false,
  onInputDone,
  placeHolder = 'Standort',
  desc = 'Wo möchten Sie Musik machen?',
  className = 'nc-flex-1.5',
  divHideVerticalLineClass = 'left-10 -right-0.5',
  setShowPopover,
  showPopover,
}) => {
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('850'));
  const { t } = useTranslation('common');

  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleGetSuggestions = async (value) => {
    handleLocalChange(value);
    setValue(value.target.value);
    const fetchLocationQueryCoordinates = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${value.target.value}.json?language=de&country=de,at,ch&types=country,place,district,region,address,postcode&access_token=${process.env.mapbox_key}`
    );
    // const fetchLocationQueryCoordinates = await fetch(
    //   `https://api.mapbox.com/search/searchbox/v1/suggest?q=${value.target.value}?limit=4&language=de&session_token=${
    //     user.id || user.temporaryID
    //   }&country=de,at,ch&access_token=${process.env.mapbox_key}`
    // );
    const getLocationQueryCoordinates = await fetchLocationQueryCoordinates.json();
    let suggestions;
    suggestions = getLocationQueryCoordinates.features?.map((sug) => {
      return { full_address: sug.place_name };
    });
    // const response = await autofill.suggest(value.target.value || '0', {
    //   sessionToken: user.id,
    //   limit: 4,
    //   language: 'de',
    //   country: 'DE,AT,CH',
    //   types: 'region',
    // });
    if (suggestions.length > 0) {
      setSuggestions(suggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSelectLocation = (item) => {
    setValue(item);
    handleLocalChange({ target: { name: 'locationInput' } }, item);
    setShowPopover(false);
    setSuggestions([]);
  };

  useEffect(() => {
    if (value.length < 0 && showPopover == 1) {
      setShowPopover(false);
      setSuggestions([]);
    }
  }, [value]);

  useEffect(() => {
    if (mobile) return;
    // !!setShowPopover && setShowPopover(autoFocus ? 1 : 0);
    if (autoFocus && !!inputRef.current) {
      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
        setShowPopover(1);
      }, 200);
    }
  }, [autoFocus]);

  useEffect(() => {
    const id = containerRef.current.getAttribute('id');
    const childDiv = containerRef.current.querySelector('.specific-class');

    function handleClickOutside(event) {
      event.stopPropagation();
      if (id == 1 && childDiv && containerRef.current && !containerRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopover, containerRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if (mobile) return;
    if (showPopover == 1 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const renderSearchValue = ({ heading }) => {
    if (!suggestions.length > 0 && !value) {
      setSuggestions([{ full_address: 'Berlin' }, { full_address: 'Hamburg' }, { full_address: 'Frankfurt' }]);
    }
    return (
      <>
        <p className="block px-4 text-sm font-medium">{heading || 'Vorschläge'}</p>
        <div className="mt-3">
          {suggestions.map((item) => (
            <span
              onClick={() => handleSelectLocation(item.full_address)}
              key={item.full_address}
              className="flex cursor-pointer items-center space-x-3 px-4 py-4 hover:bg-neutral-100 sm:px-6">
              <span className="block text-neutral-400">
                <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block text-neutral-700 ">{item.full_address}</span>
            </span>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} id={1} ref={containerRef}>
      <div
        onClick={() => setShowPopover(1)}
        className={`[ nc-hero-field-padding--small ] relative z-10 flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 text-left focus:outline-none ${
          showPopover == 1 ? 'nc-hero-field-focused--2' : ''
        }`}>
        <div className="flex-1">
          <input
            type="text"
            autoComplete="off"
            className={`block w-full truncate border-none bg-transparent p-0 font-semibold placeholder-neutral-800 focus:placeholder-neutral-400 focus:outline-none focus:ring-0 xl:text-base`}
            placeholder={placeHolder}
            name="locationInput"
            value={value}
            autoFocus={mobile ? false : showPopover == 1 ? true : false}
            onChange={(e) => {
              handleGetSuggestions(e);
            }}
            ref={inputRef}
          />
          <span className="mt-0.5 block text-sm font-light text-neutral-400 ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
          {value && showPopover == 1 && (
            <ClearDataButton
              onClick={() => {
                setValue('');
                setSuggestions([]);
              }}
            />
          )}
        </div>
      </div>

      {showPopover == 1 && (
        <div
          className={`absolute left-1 top-1/2 z-0 h-8 -translate-y-1/2 self-center bg-white ${divHideVerticalLineClass}`}></div>
      )}

      {showPopover == 1 && (
        <div className="specific-class absolute left-0 top-full z-40 mt-3 max-h-96 w-full min-w-[300px] overflow-y-auto rounded-3xl bg-white py-3 shadow-xl sm:min-w-[400px] sm:py-5">
          {value
            ? renderSearchValue({
                heading: 'Vorschläge',
              })
            : renderSearchValue({
                heading: t('popular_locations'),
              })}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
