import React, { useCallback, useEffect, useState } from 'react';
import LocationInput from '../LocationInput';
import StudioTypeSelect from '../StudioTypeSelect';
import { useTranslation } from 'next-i18next';

const TonstudioSearchForm = ({ setHrefLink }) => {
  const [fieldNameShow, setFieldNameShow] = useState('location');
  const [studiotype, setStudioType] = useState('');
  const [locationInput, setLocationInput] = useState('');
  //
  const { t } = useTranslation('common');

  useEffect(() => {
    setHrefLink({
      pathname: '/tonstudios',
      query: {
        filters: JSON.stringify({
          studioType: studiotype === 'Alle Studios' ? '' : studiotype,
          location: locationInput,
        }),
        page: 1,
      },
    });
  }, [locationInput, studiotype]);

  const handleLocalChange = useCallback((e, second, third = false) => {
    const val = e?.target?.value;
    let name = e === 'locationInput' ? 'locationInput' : e === 'studiotype' ? 'studiotype' : e?.target?.name;

    if (name === 'locationInput') {
      if (third == 'click') {
        setLocationInput(val ? val : second ? second : '');
        setFieldNameShow('propertyType');
        return;
      }
      setLocationInput(val ? val : second ? second : '');
      return;
    }
    if (name === 'studiotype') {
      let selected;
      selected = second?.filter((type) => type.checked);
      if (selected.length) setStudioType(selected[0].name);
      return;
    }
  }, []);
  //

  const renderInputLocation = () => {
    const isActive = fieldNameShow === 'location';
    return (
      <div
        className={`w-full bg-white   ${
          isActive ? 'rounded-2xl shadow-lg' : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}>
        {!isActive ? (
          <button
            className={`flex w-full justify-between p-4 text-sm font-medium`}
            onClick={() => setFieldNameShow('location')}>
            <span className="text-neutral-400">{t('where')}</span>
            <span>{locationInput || t('i_am_flexibel')}</span>
          </button>
        ) : (
          <LocationInput headingText={`${t('location')}?`} handleLocalChange={handleLocalChange} />
        )}
      </div>
    );
  };

  const renderInputStudioType = () => {
    const isActive = fieldNameShow === 'propertyType';

    return (
      <div
        className={`w-full overflow-hidden bg-white   ${
          isActive ? 'rounded-2xl shadow-lg' : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}>
        {!isActive ? (
          <button
            className={`flex w-full justify-between p-4 text-sm font-medium`}
            onClick={() => setFieldNameShow('propertyType')}>
            <span className="text-neutral-400">{t('studiotype')}</span>
            <span className="ml-5 truncate">{studiotype || t('all_studios')}</span>
          </button>
        ) : (
          <StudioTypeSelect handleLocalChange={handleLocalChange} />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {/*  */}
        {renderInputLocation()}
        {/*  */}
        {renderInputStudioType()}
        {/*  */}
      </div>
    </div>
  );
};

export default TonstudioSearchForm;
