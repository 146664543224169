import React from 'react';
import { MyLink } from '../../MyLink';
import { useTranslation } from 'next-i18next';

const ButtonSubmit = ({ href, className = '', onClick = () => {} }) => {
  const { t } = useTranslation('common');

  return (
    <MyLink
      prefetch={false}
      href={href}
      type="button"
      onClick={(e) => {
        onClick();
      }}
      className={`flex flex-shrink-0 cursor-pointer items-center justify-center rounded-xl bg-indigo-500 px-4 py-2.5 text-neutral-50 focus:outline-none ${className} relative z-20`}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
      <span className="ml-2">{t('search')}</span>
    </MyLink>
  );
};

export default ButtonSubmit;
