import React, { useCallback, useState } from 'react';
import LocationInput from '../LocationInput';
import StudioTypeSelect from './StudioTypeSelect';
import { useTranslation } from 'next-i18next';

const TonstudioForm = ({ defaultFieldFocus }) => {
  const [studiotype, setStudioType] = useState([]);
  const [locationInput, setLocationInput] = useState('');
  const [showPopover, setShowPopover] = useState(defaultFieldFocus === 'location' ? 1 : 0);
  const { t } = useTranslation('common');

  const theSearchLink = {
    pathname: '/tonstudios',
    query: {
      filters: JSON.stringify({
        studioType: studiotype === 'Alle Studios' ? '' : studiotype,
        location: locationInput,
      }),
      page: 1,
    },
  };
  //

  const handleLocalChange = useCallback((e, second) => {
    const val = e?.target?.value;
    let name = e === 'locationInput' ? 'locationInput' : e === 'studiotype' ? 'studiotype' : e?.target?.name;

    if (name === 'locationInput') {
      setLocationInput(val ? val : second ? second : '');
      return;
    }
    if (name === 'studiotype') {
      let selected;
      selected = second?.filter((type) => type.checked);
      if (selected.length) setStudioType(selected[0].name);
      return;
    }
  }, []);
  //

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const renderForm = () => {
    return (
      <form
        onKeyDown={(e) => {
          if (e?.keyCode == 13) {
            handleSubmit(e);
          }
        }}
        tabIndex="0"
        onSubmit={(event) => {
          handleSubmit(event);
        }}
        className="relative flex w-full items-center rounded-full border border-neutral-200 bg-white  ">
        <LocationInput
          // onInputDone={() => setDateFocused("startDate")}
          handleLocalChange={handleLocalChange}
          className="flex-[1.3] lg:flex-[1.5]"
          desc={t('location_input_question')}
          autoFocus={defaultFieldFocus === 'location'}
          setShowPopover={setShowPopover}
          showPopover={showPopover}
          placeHolder={t('location')}
        />
        <div className="h-8 self-center border-r border-slate-200  "></div>
        <StudioTypeSelect
          theSearchLink={theSearchLink}
          handleLocalChange={handleLocalChange}
          setShowPopover={setShowPopover}
          showPopover={showPopover}
        />
      </form>
    );
  };

  return renderForm();
};

export default TonstudioForm;
