import React, { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { HomeIcon } from '@heroicons/react/24/outline';
import Radiobutton from '../../Radiobutton';
import ButtonSubmit from '../ButtonSubmit';
import { useTranslation } from 'next-i18next';

const StudioTypeSelect = ({
  onChange,
  handleLocalChange,
  theSearchLink,
  fieldClassName = '[ nc-hero-field-padding--small ]',
  setShowPopover,
}) => {
  const { t } = useTranslation('common');

  const defaultPropertyType = [
    {
      name: t('all_studios'),
      description: t('all_studios_desc'),
      checked: true,
    },
    {
      name: t('home_studio'),
      description: t('home_studio_desc'),
      checked: false,
    },
    {
      name: t('medium_studio'),
      description: t('medium_studio_desc'),
      checked: false,
    },
    {
      name: t('premium_studio'),
      description: t('premium_studio_desc'),
      checked: false,
    },
  ];
  const [typeOfProperty, setTypeOfProperty] = useState(defaultPropertyType);

  let typeOfPropertyText = '';
  if (typeOfProperty && typeOfProperty.length > 0) {
    typeOfPropertyText = typeOfProperty
      .filter((item) => item.checked)
      .map((item) => {
        return item.name;
      })
      .join(', ');
  }

  useEffect(() => {
    handleLocalChange('studiotype', typeOfProperty);
  }, [typeOfProperty]);

  return (
    <Popover className="relative flex-1">
      {({ open, close }) => (
        <>
          <div className={`relative z-10 flex`}>
            <Popover.Button
              className={`relative z-10 flex flex-1 items-center justify-between text-left ${fieldClassName} cursor-pointer space-x-3 focus:outline-none ${
                open ? 'nc-hero-field-focused--2' : ''
              }`}
              onClickCapture={() => document.querySelector('html')?.click()}
              onClick={() => setShowPopover(2)}>
              <div className="text-neutral-300  ">
                <HomeIcon className="h-5 w-5 lg:h-7 lg:w-7" />
              </div>
              <div className="flex-1">
                <span className="block overflow-hidden font-semibold xl:text-base">
                  <span className="line-clamp-1">{typeOfPropertyText || `Type`}</span>
                </span>
                <span className="mt-0.5 block text-sm font-light text-neutral-400">{t('studiotype')}</span>
              </div>
              {/* BUTTON SUBMIT OF FORM */}
              <div className="absolute right-0 pr-2">
                <ButtonSubmit href={theSearchLink} />
              </div>
            </Popover.Button>

            {open && (
              <div className="absolute -inset-x-0.5 right-1 top-1/2 z-0 h-8 -translate-y-1/2 self-center bg-white"></div>
            )}

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute left-0 top-full z-10 mt-3 w-full max-w-sm rounded-3xl bg-white px-4 py-5 shadow-xl   sm:min-w-[340px] sm:px-8 sm:py-6">
                <div>
                  <div className="relative flex flex-col space-y-5">
                    {typeOfProperty.map((item, index) => (
                      <div key={item.name}>
                        <Radiobutton
                          name={'studiotype'}
                          id={item.name}
                          label={item.name}
                          subLabel={item.description}
                          defaultChecked={typeOfProperty[index].checked}
                          onChange={(e) => {
                            const newState = typeOfProperty.map((item, i) => {
                              if (i === index) {
                                return { ...item, checked: e };
                              } else {
                                return { ...item, checked: false };
                              }
                            });
                            setTypeOfProperty(() => {
                              return newState;
                            });
                            onChange && onChange(newState);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
};

export default StudioTypeSelect;
