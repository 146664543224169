import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';

const SearchInput = ({ handleLocalChange, onChange = () => {}, className = '', headingText = 'Keywords?' }) => {
  const [value, setValue] = useState('');

  const { t } = useTranslation('common');
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    handleLocalChange('searchInput', value);
  }, [value]);
  //

  const renderSearchValues = ({ heading, items }) => {
    return (
      <>
        <p className="block text-base font-semibold">{heading || 'Keywords'}</p>
        <div className="mt-3">
          {items.map((item) => {
            return (
              <div
                className="mb-1 flex items-center space-x-3 py-2 text-sm"
                onClick={() => {
                  setValue(item);
                  handleLocalChange('searchInput', item, 'click');
                }}
                key={item}>
                <MagnifyingGlassIcon className="h-5 w-5 text-neutral-500" />
                <span className="">{item}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block text-xl font-semibold sm:text-2xl">{headingText}</span>
        <div className="relative mt-5">
          <input
            className={`block w-full truncate rounded-xl border border-neutral-900 bg-transparent px-4 py-3 pr-12 text-base font-bold leading-none placeholder-neutral-500 placeholder:truncate focus:outline-none focus:ring-0  `}
            placeholder={t('search_input_question')}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="h-5 w-5 text-neutral-700  " />
          </span>
        </div>
        <div className="mt-7">
          {value
            ? null
            : renderSearchValues({
                heading: t('popular_keywords'),
                items: ['Mischen', 'Mastern', 'Songwriting', 'Youtube editing', 'Podcast editing'],
              })}
          {/* {value
            ? renderSearchValues({
                heading: 'Standorte',
                items: ['Afghanistan', 'Albania', 'Algeria', 'American Samao', 'Andorra'],
              })
            : renderSearchValues({
                heading: 'Beliebte Keywords',
                items: ['Mischen', 'Mastern', 'Songwriting', 'Youtube editing', 'Podcast editing'],
              })} */}
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
