import React, { useCallback } from 'react';
import NcInputNumber from '../NcInputNumber';
import { useTranslation } from 'next-i18next';

const GuestsInput = ({
  handleLocalChange,
  setGuestAdultsInputValue,
  setGuestChildrenInputValue,
  guestAdultsInputValue,
  guestChildrenInputValue,
  className = '',
}) => {
  const { t } = useTranslation('common');

  //
  const handleChangeData = useCallback(
    (value, type) => {
      let newValue = {
        guestAdults: guestAdultsInputValue,
        guestChildren: guestChildrenInputValue,
      };
      if (type === 'guestAdults') {
        setGuestAdultsInputValue(value);
        newValue.guestAdults = value;
      }
      if (type === 'guestChildren') {
        setGuestChildrenInputValue(value);
        newValue.guestChildren = value;
      }
      handleLocalChange('GuestsInput', newValue.guestAdults + newValue.guestChildren);
    },
    [(guestAdultsInputValue, guestChildrenInputValue)]
  );

  return (
    <div className={`relative flex flex-col p-5 ${className}`}>
      <span className="mb-5 block text-xl font-semibold sm:text-2xl">{t('who_is_coming')}</span>
      <NcInputNumber
        className="w-full"
        defaultValue={guestAdultsInputValue}
        onChange={(value) => handleChangeData(value, 'guestAdults')}
        max={20}
        label={t('adults')}
        desc={'13+ ' + t('years')}
      />
      <NcInputNumber
        className="mt-6 w-full"
        defaultValue={guestChildrenInputValue}
        onChange={(value) => handleChangeData(value, 'guestChildren')}
        max={4}
        label={t('children')}
        desc={'2-12 ' + t('years')}
      />
    </div>
  );
};

export default GuestsInput;
