import React, { useEffect, useState } from 'react';
import Radiobutton from '../Radiobutton';
import { useTranslation } from 'next-i18next';

const StudioTypeSelect = ({ handleLocalChange, onChange }) => {
  const { t } = useTranslation('common');

  const defaultPropertyType = [
    {
      name: t('all_studios'),
      description: t('all_studios_desc'),
      checked: true,
    },
    {
      name: t('home_studio'),
      description: t('home_studio_desc'),
      checked: false,
    },
    {
      name: t('medium_studio'),
      description: t('medium_studio_desc'),
      checked: false,
    },
    {
      name: t('premium_studio'),
      description: t('premium_studio_desc'),
      checked: false,
    },
  ];
  const [typeOfProperty, setTypeOfProperty] = useState(defaultPropertyType);
  //

  let typeOfPropertyText = '';
  if (typeOfProperty && typeOfProperty.length > 0) {
    typeOfPropertyText = typeOfProperty
      .filter((item) => item.checked)
      .map((item) => {
        return item.name;
      })
      .join(', ');
  }

  useEffect(() => {
    handleLocalChange('studiotype', typeOfProperty);
  }, [typeOfProperty]);

  return (
    <div className="p-5">
      <span className="block text-xl font-semibold sm:text-2xl">Studiolevel</span>
      <div className="relative mt-7 flex flex-col space-y-5">
        {typeOfProperty.map((item, index) => (
          <div key={item.name} className="">
            <Radiobutton
              name={'studiotype'}
              id={item.name}
              label={item.name}
              subLabel={item.description}
              defaultChecked={typeOfProperty[index].checked}
              onChange={(e) => {
                const newState = typeOfProperty.map((item, i) => {
                  if (i === index) {
                    return { ...item, checked: e };
                  } else {
                    return { ...item, checked: false };
                  }
                });
                setTypeOfProperty(() => {
                  return newState;
                });
                onChange && onChange(newState);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudioTypeSelect;
