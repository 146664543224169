import React, { useCallback, useState } from 'react';
import LocationInput from '../LocationInput';
import LokalDatesRangeInput from './LokalDatesRangeInput';
import GuestsInput from '../GuestsInput';
import SearchInput from '../SearchInput';
import PriceRangeInput from './PriceRangeInput';
import { useRouter } from 'next/router';
import { useMediaQuery, useTheme } from '@mui/material';
import { classNames } from '../../../../utils';
import { useTranslation } from 'next-i18next';

const ServicesSearchForm = ({ defaultFieldFocus }) => {
  const [dropOffLocationType, setDropOffLocationType] = useState('lokal');
  const [locationInput, setLocationInput] = useState('');
  const [checkinInput, setCheckinInput] = useState('');
  const [checkoutInput, setCheckoutInput] = useState('');
  const [guestInput, setGuestInput] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [priceInput, setPriceInput] = useState([5, 9999]);

  const { t } = useTranslation('common');
  const router = useRouter();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('850'));
  const [showPopover, setShowPopover] = useState(
    mobile
      ? false
      : defaultFieldFocus === 'location'
      ? 1
      : defaultFieldFocus === 'dates'
      ? 2
      : defaultFieldFocus === 'guests'
      ? 3
      : 1
  );
  //
  const isOnlineServices = dropOffLocationType === 'different';
  //
  const renderRadioBtn = () => {
    return (
      <div className="flex items-center justify-center space-x-3 pb-3">
        <div
          className={`flex cursor-pointer items-center rounded-full px-4 py-1.5 text-xs font-medium ${
            dropOffLocationType === 'lokal'
              ? 'bg-indigo-500 text-white shadow-lg shadow-black/10'
              : 'border border-neutral-300  '
          }`}
          onClick={(e) => setDropOffLocationType('lokal')}>
          {t('local_services')}
        </div>
        <div
          className={`flex cursor-pointer items-center rounded-full px-4 py-1.5 text-xs font-medium ${
            dropOffLocationType === 'different'
              ? 'bg-indigo-500 text-white shadow-lg shadow-black/10'
              : 'border border-neutral-300  '
          }`}
          onClick={(e) => setDropOffLocationType('different')}>
          {t('online_services')}
        </div>
      </div>
    );
  };
  //
  const localSearchLink = {
    pathname: '/lokale-leistungen',
    query: {
      filters: JSON.stringify({
        checkIn: checkinInput,
        checkOut: checkoutInput ? checkoutInput : checkinInput,
        location: locationInput,
      }),
      moreFilters: JSON.stringify({
        guest: guestInput,
      }),
      page: 1,
    },
  };
  //
  const onlineSearchLink = {
    pathname: '/online-leistungen',
    query: {
      filters: JSON.stringify({
        search: searchInput,
        priceMin: priceInput[0],
        priceMax: priceInput[1],
        sortBy: 'Empfohlen',
      }),
      page: 1,
    },
  };
  //
  const theSearchLink = isOnlineServices ? onlineSearchLink : localSearchLink;
  //
  const handleLocalChange = useCallback((e, second) => {
    const val = e?.target?.value;
    let name =
      e === 'dates'
        ? 'dates'
        : e === 'GuestsInput'
        ? 'GuestsInput'
        : e === 'searchInput'
        ? 'searchInput'
        : e === 'priceInput'
        ? 'priceInput'
        : e?.target?.name;
    if (name === 'locationInput') {
      setLocationInput(val ? val : second ? second : '');
      return;
    }
    if (name === 'dates') {
      const [start, end] = second;
      setCheckinInput(start);
      setCheckoutInput(end);
      return;
    }
    if (name === 'GuestsInput') {
      setGuestInput(second);
      return;
    }
    if (name === 'searchInput') {
      setSearchInput(second);
      return;
    }
    if (name === 'priceInput') {
      setPriceInput(second);
      return;
    }
  }, []);
  //
  const handleSubmit = (event) => {
    event.preventDefault();
    router.push(theSearchLink);
  };
  //
  const renderForm = () => {
    return (
      <form
        className="relative w-full"
        onKeyDown={(e) => {
          if (e?.keyCode == 13) {
            handleSubmit(e);
          }
        }}
        tabIndex="0"
        onSubmit={(event) => {
          handleSubmit(event);
        }}>
        {renderRadioBtn()}
        <div className="flex w-full flex-row rounded-full border border-neutral-200 bg-white  ">
          {dropOffLocationType === 'lokal' ? (
            <>
              <LocationInput
                handleLocalChange={handleLocalChange}
                placeHolder={t('location')}
                desc={t('location_input_question')}
                className="flex-1"
                autoFocus={defaultFieldFocus === 'location'}
                divHideVerticalLineClass="-inset-x-0.5"
                setShowPopover={setShowPopover}
                showPopover={showPopover}
              />
              <div className="h-8 self-center border-r border-slate-200"></div>
              <LokalDatesRangeInput
                handleLocalChange={handleLocalChange}
                className="flex-1"
                autoFocus={defaultFieldFocus === 'dates'}
                setShowPopover={setShowPopover}
                showPopover={showPopover}
              />
              <div className={classNames('h-8 self-center border-r border-slate-200')}></div>
              <GuestsInput
                theSearchLink={theSearchLink}
                handleLocalChange={handleLocalChange}
                className="flex-1"
                autoFocus={defaultFieldFocus === 'guests'}
                setShowPopover={setShowPopover}
                showPopover={showPopover}
              />
            </>
          ) : (
            <>
              <SearchInput
                handleLocalChange={handleLocalChange}
                className="flex-[1.5]"
                desc={t('search_input_question')}
                setShowPopover={setShowPopover}
                showPopover={showPopover}
              />
              <div
                className={classNames(
                  showPopover == 4 && '!border-r-transparent',
                  'h-8 self-center border-r border-slate-200'
                )}></div>{' '}
              <PriceRangeInput
                theSearchLink={theSearchLink}
                handleLocalChange={handleLocalChange}
                setShowPopover={setShowPopover}
                showPopover={showPopover}
              />
            </>
          )}
        </div>
      </form>
    );
  };

  return renderForm();
};

export default ServicesSearchForm;
