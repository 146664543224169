import React, { useCallback, useEffect, useState } from 'react';
import ClearDataButton from './ClearDataButton';
import ButtonSubmit from './ButtonSubmit';
import NcInputNumber from '../NcInputNumber';
import { useOutsideAlerter } from '../../../utils';
import { useTranslation } from 'next-i18next';

const GuestsInput = ({
  fieldClassName = '[ nc-hero-field-padding--small ]',
  className = '',
  autoFocus = false,
  theSearchLink,
  setShowPopover,
  showPopover,
}) => {
  const refContainer = React.createRef();
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(refContainer, () => setIsOpen(false));

  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(0);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);
  //
  const { t } = useTranslation('common');

  useEffect(() => {
    setIsOpen(autoFocus);
  }, [autoFocus]);

  const handleChangeData = useCallback(
    (value, type) => {
      let newValue = {
        guestAdults: guestAdultsInputValue,
        guestChildren: guestChildrenInputValue,
        guestInfants: guestInfantsInputValue,
      };
      if (type === 'guestAdults') {
        setGuestAdultsInputValue(value);
        newValue.guestAdults = value;
      }
      if (type === 'guestChildren') {
        setGuestChildrenInputValue(value);
        newValue.guestChildren = value;
      }
      // if (type === 'guestInfants') {
      //   setGuestInfantsInputValue(value);
      //   newValue.guestInfants = value;
      // }
    },
    [(guestAdultsInputValue, guestChildrenInputValue)]
  );

  const totalGuests = guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

  return (
    <div className={`relative z-10 flex ${className}`} ref={refContainer}>
      <div
        className={`relative z-10 flex flex-1 cursor-pointer items-center justify-between text-left focus:outline-none ${
          isOpen ? 'nc-hero-field-focused--2' : ''
        }`}>
        <div
          className={`${fieldClassName} flex-1`}
          onClick={() => {
            setIsOpen(!isOpen);
          }}>
          <div className="flex-1 text-left">
            <span className="block truncate font-semibold">
              {totalGuests || ''} {t('guests')}
            </span>
            <span className="mt-1 block text-sm font-light leading-none text-neutral-400">
              {totalGuests ? t('guests') : t('add_guests')}
            </span>
          </div>
        </div>
        <div className="relative">
          {!!totalGuests && isOpen && (
            <ClearDataButton
              onClick={() => {
                handleChangeData(0, 'guestAdults');
                handleChangeData(0, 'guestChildren');
              }}
            />
          )}
        </div>
        <div className="pr-2">
          <ButtonSubmit href={theSearchLink} />
        </div>
      </div>

      {isOpen && (
        <div className="absolute -left-0.5 right-10 top-1/2 z-0 h-8 -translate-y-1/2 self-center bg-white  "></div>
      )}

      {isOpen && (
        <div className="absolute right-0 top-full z-30 mt-3 w-full max-w-sm rounded-3xl bg-white px-4 py-5 shadow-xl   sm:min-w-[340px] sm:px-8 sm:py-6">
          <NcInputNumber
            className="w-full"
            defaultValue={guestAdultsInputValue}
            onChange={(value) => handleChangeData(value, 'guestAdults')}
            max={20}
            label={t('adults')}
            desc="13+ Jahre"
          />
          <NcInputNumber
            className="mt-6 w-full"
            defaultValue={guestChildrenInputValue}
            onChange={(value) => handleChangeData(value, 'guestChildren')}
            max={4}
            label={t('children')}
            desc="2-12 Jahre"
          />
        </div>
      )}
    </div>
  );
};

export default GuestsInput;
