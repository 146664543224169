import React, { Fragment, useState } from 'react';
import { Dialog, Tab } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import ButtonSubmit from './ButtonSubmit';
import { useTimeoutFn } from 'react-use';
import ServicesSearchForm from './services-search-form/ServicesSearchForm';
import TonstudioSearchForm from './tonstudio-search-form/TonstudioSearchForm';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const HeroSearchForm2Mobile = ({ dashboard }) => {
  const [showModal, setShowModal] = useState(false);
  const [hrefLink, setHrefLink] = useState({});

  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);

  const { t } = useTranslation(['common']);
  const router = useRouter();

  //

  const handleSubmit = (event) => {
    event.preventDefault();
    router.push(hrefLink);
    setShowDialog(false);
  };

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return (
      <button
        onClick={openModal}
        className={`${dashboard ? '' : ''} relative flex w-full items-center rounded-full bg-white/80 px-4 py-2 pr-11`}>
        <MagnifyingGlassIcon className="h-5 w-5 flex-shrink-0" />

        <div className="ml-3 flex-1 overflow-hidden text-left">
          <span className="block text-sm font-medium">{t('location_input_question')}</span>
          <span className="mt-0.5 block text-xs font-light text-neutral-500   ">
            <span className="line-clamp-1">
              {t('somewhere')} • {t('today')} • {t('add_guests')}
            </span>
          </span>
        </div>

        <span className="absolute right-2 top-1/2 flex h-9 w-9 -translate-y-1/2 transform items-center justify-center rounded-full border border-neutral-200  ">
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            className="block h-4 w-4"
            fill="currentColor">
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span>
      </button>
    );
  };

  return (
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Dialog as="div" open={showModal} className="HeroSearchFormMobile__Dialog z-max relative" onClose={closeModal}>
        <div className="fixed inset-0 bg-neutral-100  ">
          <div className="flex h-full">
            <Dialog.Panel className="relative flex h-full flex-1 flex-col justify-between overflow-hidden ">
              {showDialog && (
                <Tab.Group manual>
                  {/*  */}
                  {/* closemodal */}
                  <div className="absolute left-4 top-4">
                    <button className="" onClick={closeModal}>
                      <XMarkIcon className="h-5 w-5 text-black  " />
                    </button>
                  </div>
                  {/*  */}
                  <Tab.List className="flex w-full justify-center space-x-6 pt-12 text-sm font-semibold text-neutral-500   sm:space-x-8 sm:text-base">
                    {['Dienstleistungen', 'Tonstudios'].map((item, index) => (
                      <Tab key={index} as={Fragment}>
                        {({ selected }) => (
                          <div className="relative select-none outline-none focus:outline-none focus-visible:ring-0">
                            <div className={`${selected ? 'text-black  ' : ''}  `}>
                              {item == 'Dienstleistungen' ? t('services') : t('recording_studios')}
                            </div>
                            {selected && (
                              <span className="absolute inset-x-0 top-full border-b-2 border-black  "></span>
                            )}
                          </div>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                  {/*  */}
                  <div className="flex flex-1 overflow-hidden px-1.5 pt-3 sm:px-4">
                    <Tab.Panels className="hiddenScrollbar flex-1 overflow-y-auto py-4">
                      <Tab.Panel>
                        <form
                          onKeyDown={(e) => {
                            if (e?.keyCode == 13) {
                              handleSubmit(e);
                            }
                          }}
                          tabIndex="0"
                          onSubmit={(event) => {
                            handleSubmit(event);
                          }}
                          className="animate-[myblur_0.4s_ease-in-out] transition-opacity">
                          <ServicesSearchForm setHrefLink={setHrefLink} />
                        </form>
                      </Tab.Panel>
                      <Tab.Panel>
                        <form
                          onKeyDown={(e) => {
                            if (e?.keyCode == 13) {
                              handleSubmit(e);
                            }
                          }}
                          tabIndex="0"
                          onSubmit={(event) => {
                            handleSubmit(event);
                          }}
                          className="animate-[myblur_0.4s_ease-in-out] transition-opacity">
                          <TonstudioSearchForm setHrefLink={setHrefLink} />
                        </form>
                      </Tab.Panel>
                    </Tab.Panels>
                  </div>
                  {/*  */}
                  {/* Buttons */}
                  <div className="flex justify-between border-t border-neutral-200 bg-white px-4 py-3  ">
                    <button
                      type="button"
                      className="flex-shrink-0 font-semibold underline"
                      onClick={() => {
                        setShowDialog(false);
                        resetIsShowingDialog();
                      }}>
                      {t('delete_all')}
                    </button>
                    <ButtonSubmit
                      href={hrefLink}
                      onClick={() => {
                        closeModal();
                      }}
                    />
                  </div>
                </Tab.Group>
              )}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default HeroSearchForm2Mobile;
