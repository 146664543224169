import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import { convertNumbThousand } from '../../../utils';
import { useTranslation } from 'next-i18next';

const PriceRangeInput = ({ handleLocalChange }) => {
  const [pricingRange, setPricingRange] = useState([5, 9999]);
  const [minPrice, setMinPrice] = useState(pricingRange[0]);
  const [maxPrice, setMaxPrice] = useState(pricingRange[1]);

  const { t } = useTranslation('common');

  useEffect(() => {
    handleLocalChange('priceInput', pricingRange);
  }, [pricingRange]);

  const handleMinPriceChange = (e) => {
    const value = Math.max(Number(Math.min(Number(e.target.value), 9999)), 5); // Ensure value does not go below the minimum
    if (value > maxPrice) return;
    setMinPrice(value);
    setPricingRange([value, pricingRange[1]]);
  };

  const handleMaxPriceChange = (e) => {
    const value = Math.min(Number(Math.max(Number(e.target.value), 5)), 9999); // Ensure value does not go above the maximum
    if (value < minPrice) {
      setMaxPrice(minPrice);
      return;
    }
    setMaxPrice(value);
    setPricingRange([pricingRange[0], value]);
  };

  const handleSliderChange = (newRange) => {
    setPricingRange(newRange);
    const value = Math.min(Number(newRange[1]), 9999); // Ensure value does not go above the maximum
    setMaxPrice(value);
    const value2 = Math.max(Number(newRange[0]), 5); // Ensure value does not go below the minimum
    setMinPrice(value2);
  };

  return (
    <div className="p-5">
      <span className="block text-xl font-semibold sm:text-2xl">{t('price_range')}</span>
      <div className="relative mt-7 flex flex-col space-y-8">
        <div className="px-10">
          <Slider
            range
            trackStyle={{ backgroundColor: '#6366F1' }}
            handleStyle={{
              borderColor: '#6366F1',
              backgroundColor: '#6366F1',
              opacity: '100%',
            }}
            min={5}
            max={9999}
            defaultValue={[pricingRange[0], pricingRange[1]]}
            value={[pricingRange[0], pricingRange[1]]}
            allowCross={false}
            step={5}
            onChange={handleSliderChange}
          />
        </div>
        <div className="flex justify-between space-x-3">
          <div className="w-[10.15rem]">
            <div className="flex w-full items-center gap-0 rounded-md border-[0.5px] border-gray-400 px-2">
              <label className="whitespace-nowrap text-sm text-gray-400">min</label>
              <input
                max={9999}
                min={5}
                step={5}
                type="number"
                placeholder="min"
                value={minPrice}
                onChange={handleMinPriceChange}
                className="w-full border-none"
              />
              <label className="font-normal text-gray-400">€</label>
            </div>
          </div>
          <div className="w-[10.15rem]">
            <div className="flex w-full items-center gap-0 rounded-md border-[0.5px] border-gray-300 px-2">
              <label className="whitespace-nowrap text-sm text-gray-400">max</label>
              <input
                max={9999}
                min={5}
                step={5}
                type="number"
                placeholder="max"
                value={maxPrice}
                onChange={handleMaxPriceChange}
                className="w-full border-none"
              />
              <label className="font-normal text-gray-400">€</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceRangeInput;
